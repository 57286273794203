import React , {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';


const Navigation = ({history}) => {
    //acceder al state
    const supervisor = useSelector(state=>state.userInfo.userInfo.supervisor);
    const manager = useSelector(state=>state.userInfo.userInfo.manager);
    const userEmail = useSelector(state=>state.userInfo.userInfo.email);

    const [pendingPto, savePendingPto] = useState(0);
    const [loginBar, saveloginBar] = useState(true);
    const countPto = useSelector(state=>state.ptoList.countPto);
        useEffect(()=>{
            savePendingPto(countPto);
            saveloginBar(true)

        },[countPto])



        
    const logout = ()=> {
        localStorage.removeItem('token');
        console.log('logout',  localStorage.getItem('uid'))
        localStorage.removeItem('supervisor');
        localStorage.removeItem('name');
        localStorage.removeItem('manager'); 
        localStorage.removeItem('department');
        localStorage.removeItem('uid');
        localStorage.removeItem('hire_date');
        localStorage.removeItem('supervisorEmail');
        localStorage.removeItem('avatar');
        localStorage.removeItem('territory');
        localStorage.removeItem('company');
        localStorage.removeItem('email');
        localStorage.removeItem('lastname');

        saveloginBar(false);

        window.location.reload(false);
     
    }

    

    return (
        supervisor !== undefined ? (
            <nav className="navbar navigation-bar-menu navbar-expand-lg navbar-dark bg-primary justify-content-between"> 
                <div className="container">
                        <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                    <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                        {
                            pendingPto > 0 ? <span className="badge badge-pill badge-danger ml-3">{pendingPto}</span> : null
                        }
                        
                    </label>
                    
                    <div id="sidebarMenu">
                        <ul className="sidebarMenuInner">

                            { (manager)
                            ? 
                            <Fragment>
                            <li className="mega-menu">
                                <Link to={'/pto/manager'} className="nuevo-pto d-block sm-ml-3">
                                    H.R. MANAGER
                                    <span className="navigation-subtitle">Human Resources</span>
                                </Link>
                                <div className="mega-menu-content">
                                    <Link to={`/pto/calendar/${moment().format('YYYY-MM-DD')}`} className="nuevo-pto d-block sm-ml-3">
                                        MONTHLY CALENDAR VIEW
                                        <span className="navigation-subtitle">CURRENT MONTH</span>
                                    </Link>
                                    <Link to={'/pto/year-calendar'} className="nuevo-pto d-block sm-ml-3">
                                        FULL YEAR CALENDAR VIEW
                                        <span className="navigation-subtitle">PTO CALENDAR</span>
                                    </Link>
                                </div>
                            </li>
                        </Fragment>    
                            :
                                null
                            }
                            { (supervisor && !manager)
                            ? 
                                <Fragment>
                                 <li className='mega-menu'>
                                    <Link to={'/pto/manager-supervisor'} className="nuevo-pto d-block sm-ml-3">
                                        SUPERVISOR MANAGER
                                        <span className="navigation-subtitle">DEPARTMENT INFO</span>
                                    
                                </Link>
                                    <div className='mega-menu-content'>
                                <Link to={`/pto/calendar/${moment().format('YYYY-MM-DD')}`} className="nuevo-pto d-block sm-ml-3">
                                    <li>CALENDAR VIEW
                                        <span className="navigation-subtitle">CURRENT MONTH</span>
                                    </li>
                                </Link> 
                                <Link to={'/pto/year-calendar'} className="nuevo-pto d-block sm-ml-3">
                                    <li>FULL YEAR CALENDAR VIEW
                                        <span className="navigation-subtitle">PTO CALENDAR</span>
                                    </li>
                                </Link> 
                                </div>
                            </li>
                                </Fragment>    
                            :
                                null
                            }

                        <Fragment>
                        <li className='mega-menu'>
                        <Link to={'/home'} className="nuevo-pto d-block sm-ml-3">
                            
                                PTO Panel
                                <span className="navigation-subtitle">Your PTO Request</span>
                            
                        </Link>

                        <div className='mega-menu-content'>
                            { (supervisor)
                            ? 
                                <Link to={'/pto/supervisor'} className="nuevo-pto d-block sm-ml-3">
                                    <li>PENDING PTO  
                                        { pendingPto > 0 ? <span className="badge badge-pill badge-danger ml-2">{pendingPto}</span> : null}
                                        
                                        <span className="navigation-subtitle">From Your Department</span>
                                       
                                    </li>
                                    
                                </Link>
                            :
                                null
                            }

                           

                            <Link to={'/pto/new'} className="nuevo-pto d-block sm-ml-3">
                                <li> 
                                    NEW PTO
                                    <span className="navigation-subtitle">Create a new PTO Request</span>
                                </li>
                            </Link>
                            
                            
                                
                                </div>
                            </li>
                        </Fragment>
                        {/* <Fragment>
                            <li className='mega-menu'> 
                            <Link to={'/fixed-asset-request-panel'} className="nuevo-pto d-block sm-ml-3">
                               
                                    Fixed Asset Request Admin Panel
                                    <span className="navigation-subtitle">Fixed Asset Request List</span>
                               
                            </Link>
                            <div className='mega-menu-content'>
                            <Link to={'/fixed-asset-request/new'} className="nuevo-pto d-block sm-ml-3">
                                <li> 
                                    Fixed Asset
                                    <span className="navigation-subtitle">Create a new request</span>
                                </li>
                            </Link>
                            </div>
                            </li>
                        </Fragment> */}
                            { (userEmail == 'astrid.miranda@mexilink.com' || userEmail == 'julio.castro@mexilink.com' || userEmail == 'jonathan.lareo@mexilink.com')
                            ?
                            <Link to={'/user-reports'} className="nuevo-pto d-block sm-ml-3">
                                <li> 
                                    Reports
                                    <span className="navigation-subtitle">UsersReports</span>
                                </li>
                            </Link>
                            :
                            null
                            }
                            <li onClick={()=> logout()}>
                                    <Link to={'/'}  className="text-light">
                                        Logout
                                        <span className="navigation-subtitle"></span>
                                    </Link>
                            </li>   
                        </ul>
                    </div>
                    <h1>
                        <Link to={'/'} className="text-light">
                            <img src="../../mxllog.png" alt="Logo" className="img-fluid logo" />
                        </Link>
                    </h1>
                    
                </div>
            </nav>
        ) 
        : (
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary justify-content-between">
                <div className="container">
                    
                    <h1>
                        <Link to={'/'} className="text-light">
                        <img src="../../mxllog.png" alt="Logo" className="img-fluid logo" />
                        </Link>
                    </h1>
                </div>

            </nav>
        )
            
    );
};

export default Navigation;