import React, {useEffect, useState} from 'react';
// Redux
import {useDispatch, useSelector} from 'react-redux';
import { getFixedAssetRequestDetail, editFixedRequestAction } from '../actions/fixedassetrequestActions';

import Swal from 'sweetalert2';
import clienteAxiosHR from '../config/axiosHR';

const EditFixedAssetRequest = ({match, history}) => {

    // Dispatch para ejecutar
    const dispatch = useDispatch();

    // obtener el ID a editar
    const { id } = match.params;

    const assetRequest = useSelector(state => state.fixedAsset.fixedAsset);

    const [purchaseNeeded, savePurchaseNeeded] = useState(false);
    const [labelCreated, saveLabelCreated] = useState(false);
    const [paymentMethod, savePaymentMethod] = useState('');
    const [paymentSuport, savePaymentSupport] = useState('');

    const [invoice, saveInvoice] = useState('');
    // Equipment state
    const [equipment, setEquipment] = useState([]);

    const [oldAssetInformation, setOldAssetInformation] = useState([]);
    // const [tipoEquipo, setTipoEquipo] = useState('');
    // const [cantidad, setCantidad] = useState(1);

    const [status, setStatus] = useState(assetRequest.status);

    const handleCheckTerminate = (value) => {
        console.log(value);
         savePurchaseNeeded(!purchaseNeeded);
    }

    const handleCheckLabel = (value) => {
        console.log(value);
         saveLabelCreated(!labelCreated);
    }

    const editFixedRequest = (request) => dispatch(editFixedRequestAction(request));

    const agregarEquipo = () => {
        console.log(typeof(oldAssetInformation))
       // if (tipoEquipo && cantidad > 0) {
          setOldAssetInformation([...oldAssetInformation, { tipo: '', cantidad: '', brand:'', model: '', serial:'', imei: '', status:''  }]);
    
      //  }
      };
    
      const eliminarEquipo = (index) => {
        const nuevosEquipos = oldAssetInformation.filter((_, i) => i !== index);
        setOldAssetInformation(nuevosEquipos);
      };
      
      const handleFormChange = (index, event) => {
        let data = [...equipment];
        data[index][event.target.name] = event.target.value;
        setEquipment(data);
    }

    const handleOldAssetInformation = (index, event) => {
        let data = [...oldAssetInformation];
        data[index][event.target.name] = event.target.value;
        setOldAssetInformation(data);
    }

    const sumitEditFixedAsset = e => {
        e.preventDefault();

        console.log('submit', assetRequest.status === 'Under Review By I.T.');


        // let data = {
        //     equipment
        // }

        switch (assetRequest.status) {
            case 'Under Review By I.T.':

            console.log('click');

            if(purchaseNeeded && assetRequest.status == 'Under Review By I.T.') {

                Swal.fire({
                    title: '¿Are you sure?',
                    text: "Fixed Asset Request is going to be send to Treasury Department",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Send!',
                    cancelButtonText: 'Cancel'
                  }).then((result) => {
                    //  console.log(pto);
                    if (result.value) {
                        setStatus('Under Review By Treasury')
        
                        let request = assetRequest
        
                        request.status = 'Under Review By Treasury';
                        request.equipment_requested = JSON.stringify(equipment);
                        request.old_asset_information = JSON.stringify(oldAssetInformation);
                        request.purchase_needed = purchaseNeeded;
                        request.label_created = labelCreated;
                        //  request.status = 'Under Review by I.T.';
                        clienteAxiosHR.post('/request-email', request)
                        .then(respuesta => {
                            //console.log(respuesta);
                            Swal.fire(
                                'Sent',
                                'Fixed Asset Request has been Approved and email notification was sent successfully.',
                                'success'
                              )

                              editFixedRequest(request);
         
                              setTimeout(() => {
                                //window.location.reload(false);
                                history.push('/home');
                              }, 3000);
                        })
                        .catch(error => {
                            console.log(error);
                            Swal.fire(
                                'Failed',
                                'An error was occurred by approving your request.',
                                'warning'
                              )
                        })
                    }
                  })
        
                }else {

                    Swal.fire({
                        title: '¿Are you sure?',
                        text: "Fixed Asset Request is going to be send to Accounting Department",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Send!',
                        cancelButtonText: 'Cancel'
                      }).then((result) => {
                        //  console.log(pto);
                        if (result.value) {
                            setStatus('Under Review By Accounting')
            
                            let request = assetRequest
            
                            request.status = status;
                            request.equipment_requested = JSON.stringify(equipment);
                            request.old_asset_information = JSON.stringify(oldAssetInformation);
                            request.status = 'Under Review By Accounting';
                            request.purchase_needed = purchaseNeeded;
                            request.label_created = labelCreated;
                            clienteAxiosHR.post('/request-email', request)
                            .then(respuesta => {
                                //console.log(respuesta);
                                Swal.fire(
                                    'Sent',
                                    'Fixed Asset Request has been Approved and email notification was sent successfully.',
                                    'success'
                                  )

                                  editFixedRequest(request);
             
                                  setTimeout(() => {
                                    // window.location.reload(false);
                                    history.push('/home');
                                  }, 3000);
                            })
                            .catch(error => {
                                console.log(error);
                                Swal.fire(
                                    'Failed',
                                    'An error was occurred.',
                                    'warning'
                                  )
                            })
                        }
                      })

                }
                
                break;

            case 'Under Review By Treasury':

              
                Swal.fire({
                    title: '¿Are you sure?',
                    text: "Fixed Asset Request is going to be send to I.T. Department",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Send!',
                    cancelButtonText: 'Cancel'
                  }).then((result) => {
                    //  console.log(pto);
                    if (result.value) {
                        setStatus('I.T. Department are completing information')
        
                        let request = assetRequest
        
                        request.status = 'I.T. Department are completing information';
                        //request.equipment_requested = JSON.stringify(equipment);
                        //request.old_asset_information = JSON.stringify(oldAssetInformation);
                       // request.purchase_needed = purchaseNeeded;
                       // request.label_created = labelCreated;
                        request.payment_method = paymentMethod;
                        request.payment_support = paymentSuport;
                        //  request.status = 'Under Review by I.T.';
                        clienteAxiosHR.post('/request-email', request)
                        .then(respuesta => {
                            //console.log(respuesta);
                            Swal.fire(
                                'Sent',
                                'Fixed Asset Request email notification has been sent successfully.',
                                'success'
                              )

                              editFixedRequest(request);
         
                              setTimeout(() => {
                                // window.location.reload(false);
                                history.push('/home');
                              }, 3000);
                        })
                        .catch(error => {
                            console.log(error);
                            Swal.fire(
                                'Failed',
                                'An error was occurred by approving your request.',
                                'warning'
                              )
                        })
                    }
                  })
                break;

            case 'I.T. Department are completing information':

              
                Swal.fire({
                    title: '¿Are you sure?',
                    text: "Fixed Asset Request is going to be send to Accounting Department",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Send!',
                    cancelButtonText: 'Cancel'
                  }).then((result) => {
                    //  console.log(pto);
                    if (result.value) {
                        setStatus('Under Review By Accounting')
        
                        let request = assetRequest
        
                        request.status = 'Under Review By Accounting';
                        //request.equipment_requested = JSON.stringify(equipment);
                        //request.old_asset_information = JSON.stringify(oldAssetInformation);
                       // request.purchase_needed = purchaseNeeded;
                       // request.label_created = labelCreated;
                        request.invoice = invoice;
                        //request.payment_support = paymentSuport;
                        //  request.status = 'Under Review by I.T.';
                        clienteAxiosHR.post('/request-email', request)
                        .then(respuesta => {
                            //console.log(respuesta);
                            Swal.fire(
                                'Sent',
                                'Fixed Asset Request email notification has been sent successfully.',
                                'success'
                              )

                              editFixedRequest(request);
         
                              setTimeout(() => {
                                // window.location.reload(false);
                                history.push('/home');
                              }, 3000);
                        })
                        .catch(error => {
                            console.log(error);
                            Swal.fire(
                                'Failed',
                                'An error was occurred by approving your request.',
                                'warning'
                              )
                        })
                    }
                  })
                break;
            
                case 'Under Review By Accounting':

              
                Swal.fire({
                    title: '¿Are you sure?',
                    text: "Fixed Asset Request will be set as Complete",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Send!',
                    cancelButtonText: 'Cancel'
                  }).then((result) => {
                    //  console.log(pto);
                    if (result.value) {
                        setStatus('Complete')
        
                        let request = assetRequest
        
                        request.status = 'Complete';
                        request.equipment_requested = JSON.stringify(equipment);
                        //request.old_asset_information = JSON.stringify(oldAssetInformation);
                       // request.purchase_needed = purchaseNeeded;
                       // request.label_created = labelCreated;
                        //request.invoice = invoice;
                        //request.payment_support = paymentSuport;
                        //  request.status = 'Under Review by I.T.';
                        clienteAxiosHR.post('/request-email', request)
                        .then(respuesta => {
                            //console.log(respuesta);
                            Swal.fire(
                                'Sent',
                                'Fixed Asset Request email notification has been sent successfully.',
                                'success'
                              )

                              editFixedRequest(request);
         
                              setTimeout(() => {
                                // window.location.reload(false);
                                history.push('/home');
                              }, 3000);
                        })
                        .catch(error => {
                            console.log(error);
                            Swal.fire(
                                'Failed',
                                'An error was occurred by approving your request.',
                                'warning'
                              )
                        })
                    }
                  })
                break;
        
            default:
                break;
        }

        
    }

    useEffect(()=> {

       const fixedAssetRequestDetail =  (request) => dispatch(getFixedAssetRequestDetail(id));
       fixedAssetRequestDetail();
      
   }, [dispatch, id])

   useEffect(() => {
    if(Object.keys(assetRequest).length !== 0) {
        let formattedEquipment = JSON.parse(assetRequest.equipment_requested);
       setEquipment(formattedEquipment)
       
       if(assetRequest.old_asset_information !== null) {
            let formattedOldAssetInformation = JSON.parse(assetRequest.old_asset_information);
            console.log(formattedOldAssetInformation, assetRequest.old_asset_information);
             setOldAssetInformation(formattedOldAssetInformation);
        // if(assetRequest.old_asset_information.length !== 0 ) {
        //     let formattedOldAssetInformation = JSON.parse(assetRequest.old_asset_information);
        //     setOldAssetInformation(formattedOldAssetInformation);
        //    }else {
        //     setOldAssetInformation([])
        //    }
       }else {
        setOldAssetInformation([])
       }

       if (assetRequest.purchase_needed) {
        savePurchaseNeeded(true);
       }
       if(assetRequest.label_created) {
        saveLabelCreated(true);
       }

       if(assetRequest.payment_method) {
        savePaymentMethod(assetRequest.payment_method);
       }

       if(assetRequest.payment_support) {
        savePaymentSupport(assetRequest.payment_support);
       }

       if(assetRequest.invoice) {
        saveInvoice(assetRequest.invoice);
       }


    }
}, [assetRequest])

    return (
        <div className="row justify-content-center mt-5">
            
                <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Add New Fixed Asset Request</h2>
                        <form onSubmit={sumitEditFixedAsset}>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name" 

                                    defaultValue={assetRequest.employee_name}
                                    
                                    required
                                    disabled
                                />
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Position</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Position" 

                                            defaultValue=''
                                            
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>State</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="State" 

                                            defaultValue={assetRequest.territory}
                                            
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Department" 
                                            defaultValue={assetRequest.department}
                                           
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label>Cost Center</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Cost Center" 

                                            defaultValue=''
                                            
                                            required
                                            disabled
                                        />
                                    </div>
                                </div>

                              

                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label>Reason</label>
                                            <textarea 
                                                className="form-control"
                                                value={assetRequest.reason}
                                                //onChange={e=>saveReason(e.target.value)}
                                               
                                                required
                                                disabled
                                                >
                                            </textarea>
                                    </div>
                                </div>
                            </div>
                        
                            

                            <div className="row container justify-content-between">
                                
                                
                                <div className="col-md-8 col-sm-12">
                                 <label>Employee Signature:</label>
                                    <div className="form-group signatureContainer">
                                        
                                    <img width={100} height={100} src={assetRequest.employee_signature} alt="signature"/>
                                        {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                        
                                    </div>
                                    
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDateSignEmployee(e.target.value)}
                                        /> */}
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            defaultValue={assetRequest.date_employee_signature}
                                            disabled
                                            />
                                        
                                    </div>
                                </div>
                                
                            </div>
                            <hr/>
                            <div className='row justify-content-center'>
                                <div className='card p-5 m-3 d-block w-100'><h2 className='d-block w-100 text-center'>Order Status:<span className='text-primary'> {assetRequest.status}</span></h2></div>
                                <h2 className='text-center'>Purchase Needed ?</h2>
                                <div className='col-md-12'>
                                    <div className="form-group text-center">
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" id="customSwitch5" onChange={e => handleCheckTerminate(e.target.value) } 
                                            checked= {purchaseNeeded}
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch5">No / Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                // Purchase Needed
                                purchaseNeeded
                                ?
                                <>
                                <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                <label>Purchase Information:</label>
                                    <table className=' table table-responsive table-stripped'>
                                        <thead>
                                            <tr>
                                            <th>
                                                Asset Type
                                            </th>
                                            <th>
                                               Quantity
                                            </th>
                                            <th>
                                                Brand
                                            </th>
                                            <th>Cost</th>
                                            <th>Vendor</th>
                                            <th>Link</th>
                                                    
                                                
                                            
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                               equipment.map((equipo, key) => (
                                                <tr key={key}>
                                                    <td>{equipo.tipo}</td>
                                                    <td>{equipo.cantidad}</td>
                                                    <td> <input
                                                            name='brand'
                                                            placeholder='Brand'
                                                            defaultValue={equipo.brand || ''}
                                                            onChange={event => handleFormChange(key, event)}
                                                           
                                                        /></td>
                                                            <td> <input
                                                            name='cost'
                                                            placeholder='Cost'
                                                            defaultValue={equipo.cost || ''}
                                                            onChange={event => handleFormChange(key, event)}
                                                            /></td>
                                                            <td> <input
                                                                name='orderedFrom'
                                                                placeholder='Ordered From'
                                                                defaultValue={equipo.orderedFrom || ''}
                                                                onChange={event => handleFormChange(key, event)}
                                                            /></td>
                                                            <td> <input
                                                                name='link'
                                                                placeholder='Link'
                                                                defaultValue={equipo.link || ''}
                                                                onChange={event => handleFormChange(key, event)}
                                                            /></td>
                                                        
                                                
                                                    

                                                </tr>
                                               ))
                                              
                                            }
                                            
                                        </tbody>
                                    </table>

                                    
                                </div>
                            </div>
                            
                            {
                                // Treasury is working
                                assetRequest.status === 'Under Review By Treasury' || assetRequest.status ===  'I.T. Department are completing information' || assetRequest.status ===  'Under Review By Accounting'
                                //assetRequest
                            ?
                            <>
                            <div className="col-md-12 col-sm-12">
                                <div className="form-group">
                                <label>Order Information:</label>
                                    <table className=' table table-responsive table-stripped'>
                                        <thead>
                                            <tr>
                                            <th>
                                                Payment Method
                                            </th>
                                            <th>
                                               Upload Payment Method Support
                                            </th>
                                       
                                            <th>
                                                Upload Invoice / Quote
                                            </th>
                                           
                                                                      
                                            
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                              
                                                <tr>
                                                    
                                                    <td> <input
                                                            name='paymentMethod'
                                                            placeholder='Payment Method'
                                                           // defaultValue={assetRequest.payment_method || ''}
                                                            onChange={event => savePaymentMethod(event.target.value)}
                                                            value={paymentMethod}
                                                        /></td>
                                                            <td> <input
                                                            name='paymentSupport'
                                                            placeholder='Payment Support'
                                                          //  defaultValue={assetRequest.payment_support || ''}
                                                            value={paymentSuport}
                                                            onChange={event => savePaymentSupport(event.target.value)}
                                                            /></td>


                                                            <td> <input
                                                            name='invoice'
                                                            placeholder='Invoice / Quote'
                                                          //  defaultValue={assetRequest.invoice || ''}
                                                            onChange={event => saveInvoice(event.target.value)}
                                                            value={invoice}
                                                        /></td>

                                                            

                                                        
                                                    

                                                </tr>
                                              
                                              
                                            }
                                            
                                        </tbody>
                                    </table>

                                    
                                </div>
                            </div>
                              
                                    
                            {/* <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                <label>Invoice Information:</label>
                                    <table className=' table table-responsive table-stripped'>
                                        <thead>
                                            <tr>
                                            <th>
                                                Upload Invoice / Quote
                                            </th>                                              
                                            
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                              
                                                <tr>
                                                    
                                                    
                                                    

                                                </tr>
                                              
                                              
                                            }
                                            
                                        </tbody>
                                    </table>

                                    
                                </div>
                            </div> */}
                            </>
                            :
                            null

                            
                            }
                            </>
                            :
                            null

                            }

                            <hr/>
                            <div className='row justify-content-center'>
                                <h2 className='text-center'>Label Created ?</h2>
                                <div className='col-md-12'>
                                    <div className="form-group text-center">
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" id="customSwitch7" onChange={e => handleCheckLabel(e.target.value) } 
                                            checked= {labelCreated}
                                            />
                                            <label className="custom-control-label" htmlFor="customSwitch7">No / Yes</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                    <label>Asset Information:</label>
                                        <table className=' table table-responsive table-stripped'>
                                            <thead>
                                                <tr>
                                                <th>
                                                    Asset Type
                                                </th>
                                                <th>
                                                   Quantity
                                                </th>
                                                <th>
                                                Brand
                                                </th>
                                                <th>
                                                    Model
                                                </th>
                                                <th>
                                                    Serial
                                                </th>
                                                <th>IMEI</th>
                                                {
                                                    assetRequest.status === 'I.T. Department are completing information' && labelCreated
                                                ?   
                                                    <>
                                                    <th>Asset Number</th>
                                                    </>
                                                :
                                                    null
                                                }

                                                {
                                                    assetRequest.status === 'Under Review By Accounting'
                                                ?   
                                                    <>
                                                    
                                                    <th>Date Updated On The System</th>
                                                    </>
                                                :
                                                    null
                                                }
                                                
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                   equipment.map((equipo, key) => (
                                                    <tr key={key}>
                                                        <td>{equipo.tipo}</td>
                                                        <td>{equipo.cantidad}</td>
                                                        <td> <input
                                                                name='brand'
                                                                placeholder='Brand'
                                                                value={equipo.brand || ''}
                                                                onChange={event => handleFormChange(key, event)}
                                                            /></td>
                                                        <td> <input
                                                            name='model'
                                                            placeholder='Model'
                                                            value={equipo.model || ''}
                                                            onChange={event => handleFormChange(key, event)}
                                                        /></td>
                                                        <td> <input
                                                                name='serial'
                                                                placeholder='Serial'
                                                                value={equipo.serial || ''}
                                                                onChange={event => handleFormChange(key, event)}
                                                            /></td>
                                                        <td> <input
                                                            name='imei'
                                                            placeholder='IMEI'
                                                            value={equipo.imei || ''}
                                                            onChange={event => handleFormChange(key, event)}
                                                        /></td>

                                                        {
                                                            assetRequest.status === 'I.T. Department are completing information' && labelCreated
                                                            ?
                                                            <>

                                                                <td> <input
                                                                    name='assetNumber'
                                                                    placeholder='Asset Number'
                                                                    value={equipo.assetNumber || ''}
                                                                    onChange={event => handleFormChange(key, event)}
                                                                /></td>

                                                                
                                                            
                                                            </>
                                                            :
                                                            null
                                                        }

{
                                                            assetRequest.status === 'Under Review By Accounting' 
                                                            ?
                                                            <>

                                                                <td> <input
                                                                    name='dateUpdated'
                                                                    type='date'
                                                                    placeholder='Date Updated On the System'
                                                                    value={equipo.dateUpdated || ''}
                                                                    onChange={event => handleFormChange(key, event)}
                                                                /></td>
                                                            
                                                            </>
                                                            :
                                                            null
                                                        }
                                                
                                                        </tr>
                                                   ))
                                                  
                                                }
                                                
                                            </tbody>
                                        </table>

                                        
                                    </div>
                                </div>
                             

                                <div className="col-md-12 col-sm-12">
                                    <div className='col-md-4 col-sm-12'>
                                        <button type='button' className='btn btn-info' onClick={()=>agregarEquipo()}>Add</button>
                                    </div>
                                    <div className="form-group">
                                    <label>Old Asset Information:</label>
                                        <table className=' table table-responsive table-stripped'>
                                            <thead>
                                                <tr>
                                                <th>
                                                    Asset Type
                                                </th>
                                                <th>
                                                   Quantity
                                                </th>
                                                <th>
                                                Brand
                                                </th>
                                                <th>
                                                    Model
                                                </th>
                                                <th>
                                                    Serial
                                                </th>
                                                <th>IMEI</th>
                                                <th>Status</th>
                                              
                                                
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    oldAssetInformation !== null 
                                                    ?
                                                   oldAssetInformation.map((oldAsset, key) => (
                                                    <tr key={key}>
                                                        <td> <input
                                                                name='tipo'
                                                                placeholder='Laptop, CellPhone, Tablet'
                                                                value={oldAsset.tipo || ''}
                                                                onChange={event => handleOldAssetInformation(key, event)}
                                                            /></td>
                                                        <td> <input
                                                                name='cantidad'
                                                                placeholder='Quantity'
                                                                value={oldAsset.cantidad || ''}
                                                                onChange={event => handleOldAssetInformation(key, event)}
                                                            /></td>
                                                        <td> <input
                                                                name='brand'
                                                                placeholder='Brand'
                                                                value={oldAsset.brand || ''}
                                                                onChange={event => handleOldAssetInformation(key, event)}
                                                            /></td>
                                                        <td> <input
                                                            name='model'
                                                            placeholder='Model'
                                                            value={oldAsset.model || ''}
                                                            onChange={event => handleOldAssetInformation(key, event)}
                                                        /></td>
                                                        <td> <input
                                                                name='serial'
                                                                placeholder='Serial'
                                                                value={oldAsset.serial || ''}
                                                                onChange={event => handleOldAssetInformation(key, event)}
                                                            /></td>
                                                        <td> <input
                                                            name='imei'
                                                            placeholder='IMEI'
                                                            value={oldAsset.imei || ''}
                                                            onChange={event => handleOldAssetInformation(key, event)}
                                                        /></td>

                                                        <td> <input
                                                            name='status'
                                                            placeholder='Status Old Asset'
                                                            value={oldAsset.status || ''}
                                                            onChange={event => handleOldAssetInformation(key, event)}
                                                        /></td>

                                                        <td><button className='btn btn-danger p-1' onClick={() => eliminarEquipo(key)}>X</button></td>

                                                    </tr>
                                                   ))
                                                   :null
                                                  
                                                }
                                                
                                            </tbody>
                                        </table>

                                        
                                    </div>
                                </div>
                            

                                <hr />

                                <button type="submit" className="btn btn-primary btn-md font-weight-bold text-uppercase  mb-4 mt-4">Save</button>

                        </form>

                        
        
                        </div>
                    </div>
                </div>
            
        </div>
    );
};

export default EditFixedAssetRequest;